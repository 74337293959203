import InlinePropertyDiagramIcon from "@/components/help-center/custom-tags/InlinePropertyDiagramIcon.vue";

export const inlinePropertyDiagramIcon = {
  render: InlinePropertyDiagramIcon,
  attributes: {
    name: {
      type: String,
      default: "empty",
      required: true,
    },
  },
};
