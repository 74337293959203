<template>
  <span class="inline-flex items-baseline">
    <div class="relative cursor-default mr-1">
      <div
        v-if="buttonData.keyboardShortcut"
        class="absolute flex items-center justify-center -top-1 -left-1 h-3 w-3 text-xs font-semibold rounded ring ring-gray-800 bg-gray-700 text-white uppercase"
        style="z-index: 10"
      >
        {{ buttonData.keyboardShortcut }}
      </div>
      <div
        :class="`w-8 h-8 flex items-center justify-center font-bold rounded ${buttonData.buttonBackgroundColor} ${buttonData.buttonTextColor}`"
      >
        <i
          v-if="buttonData.buttonType === 'iconClass'"
          :class="`text-lg ${buttonData.buttonIconClass}`"
        />
        <svg
          v-else-if="buttonData.buttonType === 'iconSvg'"
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          v-html="pathsForIcon(buttonData.buttonSvgPaths)"
        ></svg>
        <template v-else>?</template>
      </div>
    </div>
    <slot />
  </span>
</template>

<script setup>
import pathsForIcon from "@/assets/pathsForIcon";
import centerConsoleActions from "@/components/crowdsourcing/centerConsoleActions";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["name"]);

const buttonData = computed(() => {
  return centerConsoleActions[props.name];
});
</script>
